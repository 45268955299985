<template>
  <OnboardingStep
    :title="$t('onboarding.installIntroView.title')"
    :next-text="$t('onboarding.installIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.installIntroView.paragraphs">
      <template #tip-alert>
        <VxAlert type="info">
          <b>{{ $t("onboarding.installIntroView.tipAlert.prefix") }}</b>
          {{ $t("onboarding.installIntroView.tipAlert.text") }}
        </VxAlert>
      </template>
      <template #install-link>
        <RaiLink
          :href="$t('onboarding.installIntroView.installLink.href')"
          external
        >
          {{ $t("onboarding.installIntroView.installLink.text") }}
        </RaiLink>
      </template>
    </I18nArray>
    <p class="mb-4">
      {{ $t("onboarding.installIntroView.emailInstructions") }}:
    </p>
    <VxForm v-slot="{ submitting }" ref="form" @submit="sendEmailInstructions">
      <v-row>
        <v-col cols="12" md="6">
          <VxTextField
            v-model="form.email"
            :label="$t('onboarding.installIntroView.emailField.label')"
            name="email"
            type="email"
            append-icon="$email"
            autocomplete="off"
            :placeholder="
              $t('onboarding.installIntroView.emailField.placeholder')
            "
            rules="required|email"
          />
        </v-col>
      </v-row>
      <VxBtn :loading="submitting" depressed type="submit">
        {{ $t("onboarding.installIntroView.submitEmailButton.text") }}
      </VxBtn>
    </VxForm>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import { hasSnackbarAccess } from "@/mixins/ui";
import { SEND_EMAIL_INSTALL_INSTRUCTIONS } from "./graphql";

import I18nArray from "@/components/I18nArray";
import { RaiLink, VxAlert, VxTextField, VxBtn, VxForm } from "@/core-ui";

export default {
  name: "InstallIntroView",
  components: { I18nArray, RaiLink, VxAlert, VxTextField, VxBtn, VxForm },
  extends: BaseStepView,
  mixins: [hasSnackbarAccess],
  data: () => ({
    form: {
      email: null,
    },
  }),
  methods: {
    async sendEmailInstructions({ values, resolve, setErrors }) {
      try {
        const response = await this.$apollo.mutate({
          mutation: SEND_EMAIL_INSTALL_INSTRUCTIONS,
          variables: {
            input: values,
          },
        });

        const { errors } = response.data.notifySendEmailInstallInstructions;

        if (errors?.length > 0) {
          setErrors(errors);
          return;
        }

        resolve();
        this.form.email = null;
        this.showSnackbar({ text: "Email was sent, it will arrive soon" });
      } catch (e) {
        setErrors(["Error has happened while sending email instructions"]);
      }
    },
  },
};
</script>
